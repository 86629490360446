/*
 * @FileDescription: 获取路由数组
 */
export default {
  data() {
    return {
      menu: []
    };
  },

  mounted() {
    this.initRoute();
  },

  methods: {
    // 初始化路由
    initRoute() {
      this.$nextTick(() => {
        this.menu = this.$router?.options.routes[0].children;
      });
    },

    // 跳转页面
    go(path) {
      this.$router.push(path);
    }
  }
};
