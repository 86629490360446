import FooTer from "./FooTer";
import NavBar from "./NavBar";
import ArticleTitle from "./ArticleTitle";
import MyPagination from "./Pagination";
import AMap from "./AMap/index.vue";
import ParkingCard from "./ParkingCard";

import {
  Button,
  Pagination,
  Rate,
  Avatar
} from "element-ui";

const components = {
  install(Vue) {
    Vue.component("FooTer", FooTer);
    Vue.component("NavBar", NavBar);
    Vue.component("ArticleTitle", ArticleTitle);
    Vue.component("MyPagination", MyPagination);
    Vue.component("AMap", AMap);
    Vue.component("ParkingCard", ParkingCard);
    Vue.component(Pagination.name, Pagination);
    Vue.component(Button.name, Button);
    Vue.component(Rate.name, Rate);
    Vue.component(Avatar.name, Avatar);
  }
};

export default components;
