import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import components from "./components"; // 全局组件注册
import AMap from "./components/AMap/index.js"; // 高德地图
import VueAwesomeSwiper from "vue-awesome-swiper"; // swiper 轮播图
import "swiper/dist/css/swiper.css";
import "./assets/styles/index.less"; // less
import "./utils/rem"; // rem适配
import "element-ui/lib/theme-chalk/base.css"; // element 内置过渡动画

import day from "dayjs"; // dayjs
import "dayjs/locale/zh-cn";
day.locale("zh-cn");
Vue.prototype.dayjs = day;

Vue.use(AMap);
Vue.use(components);
Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
