<!--
 * @FileDescription: 地图
-->
<template>
  <div id="AMap">
    <div class="tip">
      <img src="@/assets/img/home/map.png" alt="" />
    </div>
    <div class="card">
      <ParkingCard v-if="cardInfo" isLookDetail :info="cardInfo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AMap",
  props: {
    // 标记点
    marker: {
      type: [Object, Array],
      default: () => {}
    }
  },

  data() {
    return {
      map: null,
      infoWindow: null, // 信息窗口实例
      cardInfo: null
    };
  },

  mounted() {
    this.initAMap();
  },

  methods: {
    // init Map
    initAMap() {
      this.map = new AMap.Map("AMap", {
        center: [114.974906, 30.104126], // 大冶市
        zoom: 15
      });
      // 窗体信息实例
      this.infoWindow = new AMap.InfoWindow({
        anchor: "top-left",
        offset: new AMap.Pixel(0, 0)
      });
    },
    // 创建 marker
    createMarker() {
      const content = document.querySelector(".card");

      // 多点
      if (Array.isArray(this.marker)) {
        const markers = [];

        this.marker.forEach((item) => {
          const marker = new AMap.Marker({
            position: [item.lng, item.lat], // 要展示marker的经度、纬度
            icon: require(`@/assets/img/home/map-icon-${item.spaceStatus}.png`), // 显示的图标
            offset: [-14, -34] // 偏移量设置为图标宽度的负一半，负整个高度。
          });

          marker.content = content;
          marker.on("click", (e) => this.handleClickMarker(e, item));
          markers.push(marker);
          // marker.emit("click", { target: marker }); // 此处是设置默认出现信息窗体
        });
        this.map.add(markers); // 添加到地图
      } else {
        // 单点
        const { lng, lat, spaceStatus } = this.marker;
        const marker = new AMap.Marker({
          position: [lng, lat],
          icon: require(`@/assets/img/home/map-icon-${spaceStatus}.png`),
          offset: new AMap.Pixel(16, -50)
        });

        this.map.add(marker);
        this.map.setFitView(); // 自动适应显示想显示的范围区域
      }
    },

    // 点击 marker
    handleClickMarker(e, value) {
      this.cardInfo = value;
      const { diagram, businessHours } = value;
      const defaultImg = require("@/assets/img/home/default_consult.png"); // 默认分布图

      /* 分布图处理 */
      this.cardInfo.imgUrl = diagram ? value.diagram.split(",")[0] : defaultImg;
      /* 营业时间处理 */
      this.cardInfo.time = JSON.parse(businessHours);

      this.infoWindow.setContent(e.target.content); // 设置窗体信息内容
      this.infoWindow.open(this.map, e.target.getPosition()); // 打开窗体信息
    }
  },

  watch: {
    marker(newVal, oldVal) {
      if (newVal) {
        this.createMarker();
      }
    }
  },

  destroyed() {
    this.map.destroy();
  }
};
</script>

<style lang="less" scoped>
#AMap {
  position: relative;
  width: 100%;
  height: 100%;
  .tip {
    position: absolute;
    z-index: 9;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    height: 28px;
    img {
      height: 100%;
    }
  }
}
</style>
