import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/layout";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "/home",
        component: () => import("@/views/home/index.vue"),
        meta: { title: "首页" }
      },
      {
        path: "/consult",
        component: () => import("@/views/consult/index.vue"),
        meta: { title: "资讯公告", isFather: true },
        children: [
          {
            path: "detail",
            component: () => import("@/views/consult/detail.vue"),
            meta: {
              title: "资讯公告-详情",
              isFather: false,
              fPath: "/consult"
            }
          }
        ]
      },
      {
        path: "/lightspot",
        component: () => import("@/views/lightspot/index.vue"),
        meta: { title: "产品亮点" }
      },
      {
        path: "/parking",
        component: () => import("@/views/parking/index.vue"),
        meta: { title: "停车场信息", isFather: true },
        children: [
          {
            path: "detail",
            component: () => import("@/views/parking/detail.vue"),
            meta: {
              title: "停车场信息-详情",
              isFather: false,
              fPath: "/parking"
            }
          }
        ]
      },
      {
        path: "/app",
        component: () => import("@/views/app/index.vue"),
        meta: { title: "移动客户端" }
      },
      {
        path: "/about",
        component: () => import("@/views/about/index.vue"),
        meta: { title: "关于我们" }
      }
    ]
  }
];

// 防止连续点击多次路由报错
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: () => ({ y: 0, behavior: "smooth" })
});

// 进度条 - 关闭自带旋转样式
NProgress.configure({ showSpinner: false });
// 前置守卫
router.beforeEach((to, from, next) => {
  NProgress.start(); // 开启进度条
  next();
});
// 后置守卫
router.afterEach((to) => {
  NProgress.done(); // 关闭进度条
  document.title = `大冶智慧停车 - ${to.meta.title || ""} `; // 设置网页标题
});

export default router;
