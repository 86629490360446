<template>
  <footer>
    <div class="content">
      <div class="left">
        <ul class="menu">
          <li v-for="(item, index) in menu" :key="index" @click="go(item.path)">
            {{ item.meta.title }}
          </li>
        </ul>
        <div class="job-info">
          <img src="@/assets/img/footer/phone.png" alt="" />
          <div class="job-info-right">
            <div class="phone">400888888</div>
            <div class="time">工作日 10：00-19：00</div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="code">
          <p>官方微信公众号</p>
          <img src="@/assets/img/footer/code.png" alt="" />
        </div>
        <div class="code">
          <p>安卓移动端下载</p>
          <img src="@/assets/img/footer/code.png" alt="" />
        </div>
      </div>
    </div>

    <div class="copyright">
      <ul>
        <li>
          <a
            href="http://protocol.dyzhtc.com/userServiceAgreementWebsite.html"
            target="_blank"
          >
            《隐私权政策》
          </a>
        </li>
        <!-- <li>
          <a
            href="#"
            target="_blank"
          >
            《权益保障承诺书》
          </a>
        </li> -->
        <li>
          <a
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
          >
            鄂ICP备2023006128号-1
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>
import router from "@/mixins/useRouter.js";

export default {
  name: "FooTer",

  mixins: [router]
};
</script>

<style lang="less" scoped>
footer {
  position: relative;
  height: 460px;
  color: #fff;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../../assets/img/footer/bg.png");
  .content {
    display: flex;
    justify-content: space-between;
    padding: 87px 340px;
    .left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .menu {
        display: flex;
        li {
          cursor: pointer;
          white-space: nowrap;
          font-size: 20px;
          margin-right: 30px;
        }
      }
      .job-info {
        display: flex;
        img {
          width: 36px;
          height: 36px;
          margin-top: 16px;
          margin-right: 22px;
        }
        &-right {
          .phone {
            font-size: 48px;
            font-weight: 600;
          }
          .time {
            font-size: 20px;
          }
        }
      }
    }
    .right {
      display: flex;
      .code {
        margin-left: 40px;
        p {
          font-size: 20px;
          text-align: center;
          margin-bottom: 10px;
        }
        img {
          width: 180px;
          height: 180px;
        }
      }
    }
  }
  .copyright {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 22px 740px;
    background-color: rgba(255, 255, 255, 0.1);
    ul {
      opacity: 0.6;
      display: flex;
      justify-content: center;
      li {
        cursor: pointer;
        white-space: nowrap;
        font-size: 16px;
      }
    }
  }
}
</style>
