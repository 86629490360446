<template>
  <div class="layout">
    <NavBar />
    <main>
      <router-view />
    </main>
    <FooTer />
  </div>
</template>

<script>
export default {
  name: "Layout_"
};
</script>

<style lang="less" scoped></style>
