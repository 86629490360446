<!--
 * @FileDescription: 文章标题
-->
<template>
  <div class="title">
    <span class="zh-CN">{{ title }}</span>
    <span class="split"></span>
    <span class="us">{{ titleUs }}</span>
  </div>
</template>

<script>
export default {
  name: "ArticleTitle",
  props: {
    title: {
      type: String,
      default: "标题"
    },
    titleUs: {
      type: String,
      default: "US"
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  display: flex;
}
.zh-CN {
  transform: translateY(-5px);
  color: #1a1a1a;
  font-size: 40px;
  font-weight: 600;
  white-space: nowrap;
}
.split {
  display: block;
  transform: rotate(-150deg);
  width: 2px;
  height: 73px;
  margin: 0 15px;
  background-color: #d8d8d8;
}
.us {
  transform: translateY(15px);
  color: #e4e4e4;
  font-size: 48px;
  font-weight: 500;
  white-space: nowrap;
}
</style>
