/*
 * @FileDescription: 高德地图注册
 */
import AMapLoader from "@amap/amap-jsapi-loader";

window._AMapSecurityConfig = {
  securityJsCode: "9a2aba61aca4b1999cea2704942a8fc1"
};

const AMap = await AMapLoader.load({
  key: "5a11f782fcdfb39405c0a1563fa463b2",
  version: "2.0", // 指定要加载的 JSAPI 的版本，缺少时默认为 1.4.15
  plugins: ["AMap.Scale", "AMap.GeoJSON"] // 需要使用的的插件列表，如比例尺'AMap.Scale'等，更多插件请看官方文档
});

export default AMap;
