<!--
 * @FileDescription: 停车场信息-卡片
-->
<template>
  <div class="card-item" @click="$router.push(`/parking/detail?id=${info.id}`)">
    <div class="img">
      <img :src="info.imgUrl" />
    </div>
    <div class="detail">
      <div class="top">
        <div class="lotName ellipsis">{{ info.lotName }}</div>
        <div class="right">
          <el-rate :value="info.level" disabled />
          <div class="level">{{ info.level?.toFixed(1) }}</div>
          <div class="evaluate nowrap">
            {{
              info.evaluatesCount ? `${info.evaluatesCount}条评价` : "暂无评价"
            }}
          </div>
        </div>
      </div>
      <div class="address ellipsis">地址：{{ info.address }}</div>
      <div class="time">
        营业时间：{{ info.time?.startTime }} - {{ info.time?.endTime }}
      </div>
      <div v-if="isLookDetail" class="btn">
        <el-button type="primary" size="small" round>查看详情</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ParkingCard",

  props: {
    info: {
      type: Object,
      default: () => {}
    },
    // 查看详情按钮
    isLookDetail: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="less" scoped>
.card-item {
  cursor: pointer;
  width: 400px;
  height: 390px;
  background-color: #fff;
  .img {
    height: 240px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .detail {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 36px;
    height: 150px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lotName {
        color: #383b46;
        font-size: 20px;
        font-weight: 600;
      }
      .right {
        display: flex;
        align-items: center;
        .el-rate {
          display: flex;
        }
        .level {
          color: #fc7a1e;
          padding-left: 4px;
          padding-right: 10px;
        }
        .evaluate {
          color: #9b9da3;
          font-size: 12px;
        }
      }
    }
    .address {
      color: #9b9da3;
      font-size: 14px;
    }
    .time {
      color: #383b46;
      font-size: 14px;
    }
    .btn {
      position: absolute;
      right: 24px;
      bottom: 22px;
      .el-button {
        width: 100px;
        background-color: #3578ff;
      }
    }
  }
}
</style>
