/*
 * 屏幕自适应穿透px为rem模板
 */
const baseSize = 16; // 设计稿根字体大小

function setRem() {
  const clientWidth = document.documentElement.clientWidth;

  if (clientWidth >= 762 && clientWidth <= 2040) {
    const scale = clientWidth / 1920; // 计算缩放比例
    const fontSize = baseSize * Math.min(scale, 2) + "px"; // 计算根字体大小

    document.documentElement.style.fontSize = fontSize; // 设置根字体大小
  }
  if (clientWidth >= 300 && clientWidth <= 762) {
    document.documentElement.style.fontSize = 62.5 + "%"; // 设置根字体大小
  }
}

setRem();
window.addEventListener("resize", setRem);
