<template>
  <nav>
    <transition name="el-zoom-in-top">
      <div v-show="show" class="wrap">
        <div class="logo" @click="go('/')">
          <img src="@/assets/img/logo.png" alt="logo" />
          <h1>大冶智慧停车</h1>
        </div>
        <ul class="menu">
          <li
            v-for="(item, index) in menu"
            :key="index"
            :class="{
              active: [$route.path, $route.meta.fPath].includes(item.path)
            }"
            @click="go(item.path)"
          >
            {{ item.meta.title }}
          </li>
        </ul>
      </div>
    </transition>
  </nav>
</template>

<script>
import router from "@/mixins/useRouter.js";

export default {
  name: "NavBar",

  mixins: [router],

  data() {
    return {
      show: true,
      scrollY: 0
    };
  },

  mounted() {
    window.addEventListener("scroll", (e) => {
      this.scrollY = window.scrollY;
    });
  },

  watch: {
    scrollY(newVal, oldVal) {
      // eslint-disable-next-line no-unneeded-ternary
      this.show = oldVal > newVal ? true : false;
    }
  }
};
</script>

<style lang="less" scoped>
nav {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 340px;
    background-color: rgba(0, 0, 0, 0.6);
    .logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
      }
      h1 {
        color: #fff;
        font-size: 30px;
        font-weight: normal;
        white-space: nowrap;
      }
    }
    .menu {
      display: flex;
      li {
        cursor: pointer;
        white-space: nowrap;
        color: #fff;
        font-size: 18px;
        margin-left: 50px;
        padding-bottom: 6px;
      }
    }
  }
}
/* 选中导航 */
.active {
  border-bottom: 3px solid #fff;
}
</style>
