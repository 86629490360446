<!--
 * @FileDescription: 分页器
-->
<template>
  <el-pagination
    background
    popper-class="aaaaa"
    :current-page="params.page"
    :page-size="params.size"
    :total="params.total"
    layout="total, prev, pager, next"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script>
export default {
  name: "MyPagination",

  props: {
    params: {
      type: Object,
      default: () => ({
        // 当前页数
        page: 1,
        // 每页显示条目个数
        size: 10,
        // 总数
        total: 0
      })
    }
  },

  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    }
  }
};
</script>

<style lang="less">
.el-pagination {
  text-align: right;
  padding: 0;
  .el-pager {
    .active {
      color: #0256ff !important;
      background-color: #e5eeff !important;
    }
  }
  .btn-next {
    margin-right: 0 !important;
  }
  .btn-prev,
  .btn-next,
  .el-pager > li {
    background-color: #fff !important;
  }
}
</style>
